//COLORS
$background: #1c1c1e;
$gray: #adadb2;
$success: #30d159;
$warning: #ff0000;
$text-gray: #adadb2;

$p-s: #26893f;
$p-r: #a92525;
$p-y: #bd7a2b;
$p-g: #959595;
