// IMPORTS
@import "const.scss";

//FONTS
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera Pro Black.otf");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera Pro Black Italic.otf");
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera Pro Black Italic.otf");
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Bold.otf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Bold\ Italic.otf");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Medium.otf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Medium\ Italic.otf");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Regular.otf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Regular\ Italic.otf");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Light.otf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Light\ Italic.otf");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Thin.otf");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Gilroy";
  src: url("fonts/Cera\ Pro\ Thin\ Italic.otf");
  font-style: italic;
  font-weight: 100;
}

//CLEAR
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

[role="button"] {
  cursor: pointer;
}
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;

  #root {
    flex: 1;
    display: flex;
    padding: 0 10px;
    font-display: column;
  }
  .app {
    flex: 1;
    display: flex;
    flex-direction: column;

    .main {
      flex: 1;
      display: flex;
      margin-top: -8px;
      position: relative;
      flex-direction: column;

      & > * {
        margin-top: 8px;
        margin-bottom: 8px;

        &:not(:header) {
          z-index: 0;
          position: relative;
        }
      }
    }
  }
}
.center {
  padding: 20px 0;
  text-align: center;

  a:hover {
    text-decoration: underline;
  }
}
.centered_f {
  width: 100%;
  height: 100%;

  top: 0;
  left: -10px;
  z-index: 1100;
  position: absolute;
  background-color: #00000030;

  padding: 0 10px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: content-box;
}
body {
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 1px;
  font-family: Gilroy;
  background-color: $background;

  input,
  button {
    color: #fff;
    font-size: 18px;
    letter-spacing: 2px;
    font-family: Gilroy;
  }

  img {
    width: auto;
    max-width: 100%;
  }

  .menu-page,
  .auth-page {
    margin-left: 30px;
    margin-right: 30px;
    justify-content: center;
  }
  .scanner {
    flex: 1;
    background-color: #292929;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}
.QR_SCANNER {
  & > section {
    height: 100%;
  }
}
.nothing {
  flex: 1;
  display: flex;
  margin-top: -80px !important;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.scanner-page {
  .scanner-page__sel-types {
    left: 50%;
    bottom: 100px;
    width: 280px;
    position: absolute;
    z-index: 1000;
    margin: -10px 0;
    transform: translateX(-50%);
  }
  .scanner-page__c {
    padding: 10px 0;
    display: flex;
  }
  .scanner-page__sel-type {
    flex: 0 0 50%;
    min-width: 50%;
    cursor: pointer;
    transition: 0.15s;
    padding: 0.5rem 1rem;
    background-color: $p-g;
    text-align: center;

    &:hover {
      background-color: $p-g + 30;
    }
    &.scanner-page__sel-type_active {
      color: #fff;
      background-color: $p-s;
    }
  }
}

.stats {
  text-align: center;
}
