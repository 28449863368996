.card {
  width: 100%;
  color: #000;
  margin: 0 10px;
  padding: 40px 20px;
  border-radius: 12px;
  text-align: center;
  position: relative;
  background-color: #959595;
  transition: 0.25s;

  .card__title {
    font-size: 1.4em;
    font-weight: bold;
    padding-bottom: 20px;
  }
  .card__message {
    font-size: 1.1em;
    font-weight: 500;

    p {
      padding: 12px 0;
    }
  }
  .card__close {
    width: 32px;
    height: 32px;
    top: 8px;
    right: 8px;
    cursor: pointer;
    position: absolute;

    path {
      transition: 0.1s;
    }
    &:hover {
      path {
        fill: #fff;
      }
    }
  }
  &.card_success {
    color: #fff;
    background-color: #26893f;
  }
  &.card_warning {
    background-color: #bd7a2b;
  }
  &.card_error {
    color: #fff;
    background-color: #541f21;
  }

  &.card_open {
    opacity: 1;
  }
  &.card_a {
    animation-duration: 0.3s;
    animation-name: rotating;
  }
  &.card_close {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }
  20% {
    transform: rotate(-3deg);
  }
  40% {
    transform: rotate(3deg);
  }
  60% {
    transform: rotate(-3deg);
  }
  80% {
    transform: rotate(3deg);
  }
  to {
    transform: rotate(0);
  }
}
